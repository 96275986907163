import { createSelector } from 'reselect';

import { MediaItem, SurveyMedia } from '@cpm/scanifly-shared-data';
import { RootState } from 'state/store';

import { ALL_MEDIA } from './constants';
import { MediaState } from './types';

const selectMediaState = (state: RootState): MediaState => state.newmedia;

const selectMediaMap = createSelector([selectMediaState], (mediaState) => mediaState.mediaMap);

const selectProject = (state: RootState, projectId?: string) => {
  const mediaMap = selectMediaMap(state);
  return projectId ? mediaMap[projectId] : undefined;
};

const selectCategory = createSelector(
  [selectProject, (_: RootState, __: string, categoryId?: string) => categoryId],
  (project, categoryId) => (project && categoryId ? project.categoryMap[categoryId] : undefined)
);

const selectMedia = (state: RootState, projectId: string) => {
  const project = selectProject(state, projectId);
  return project ? project.media : {};
};

const selectClientRefIdMap = createSelector(
  [selectMediaState],
  (mediaState) => mediaState.clientRefIdMap
);

const selectMediaOrder = (state: RootState, projectId: string, categoryId: string) => {
  const category = selectCategory(state, projectId, categoryId);
  return category ? category.mediaOrder : [];
};

export const selectMediaByCategory = createSelector(
  [selectMedia, selectMediaOrder],
  (media, mediaOrder) => {
    return mediaOrder.map((mediaId) => media[mediaId]).filter(Boolean);
  }
);

export const selectMediaByClientRefIds = createSelector(
  [selectMediaMap, selectClientRefIdMap, (_: RootState, clientRefIds?: string[]) => clientRefIds],
  (mediaMap, clientRefIdMap, clientRefIds) => {
    const responseObject: { [key: string]: MediaItem | SurveyMedia } = {};
    if (!clientRefIds) return responseObject;

    for (const refId of clientRefIds) {
      const refIdEntry = clientRefIdMap[refId];
      if (refIdEntry) {
        const { projectId, mediaId } = refIdEntry;
        const projectMediaMap = mediaMap[projectId];
        const media = projectMediaMap?.media ? projectMediaMap.media[mediaId] : undefined;
        if (media) {
          responseObject[refId] = media;
        }
      }
    }

    return responseObject;
  }
);

export const selectMediaRequestStatus = createSelector([selectCategory], (category) =>
  category
    ? { status: category.requestStatus, lastRequested: category.lastRequested }
    : { status: undefined, lastRequested: undefined }
);

export const selectAllMediaCategory = createSelector(
  [selectProject, (_: RootState, __: string) => ALL_MEDIA],
  (project, categoryId) => (project ? project.categoryMap[categoryId] : undefined)
);

export const selectAllMedia = createSelector(
  [selectMedia, selectAllMediaCategory],
  (media, allMediaCategory) => {
    if (!allMediaCategory?.mediaOrder) return [];
    return allMediaCategory.mediaOrder.map((mediaId) => media[mediaId]).filter(Boolean);
  }
);

export const selectAllMediaRequestStatus = createSelector([selectAllMediaCategory], (category) =>
  category
    ? { status: category.requestStatus, lastRequested: category.lastRequested }
    : { status: undefined, lastRequested: undefined }
);
