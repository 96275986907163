import React from 'react';

import { CopyOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import { openNotification } from 'helpers/utils/openNotification';
import AccessTokenStyle from './AccessTokenStyle';

interface TokenDisplayModalProps {
  handleModalClose: () => void;
  accessToken: string | null;
}

const GenerateTokenDisplayModal: React.FC<TokenDisplayModalProps> = ({
  handleModalClose,
  accessToken,
}) => {
  const [modalTitle, setModalTitle] = React.useState('');

  const handleClose = () => {
    handleModalClose();
    setModalTitle('');
  };

  const handleCopyToken = async () => {
    if (accessToken) {
      try {
        await navigator.clipboard.writeText(accessToken);
        setModalTitle('Access token has been copied to the clipboard.');
      } catch {
        setModalTitle('');
        openNotification({
          type: 'error',
          title: 'Copy Failed',
          text: 'Failed to copy access token to the clipboard. Please copy it manually.',
        });
      }
    }
  };

  const MODAL_PROPS = {
    role: 'dialog',
    'aria-modal': true,
    destroyOnClose: true,
    centered: true,
    children: (
      <AccessTokenStyle>
        <div className="AccessToken-GenerateTokenModal-Dialogue">
          <Button onClick={handleClose} className="Button--Red">
            Close
          </Button>
        </div>
      </AccessTokenStyle>
    ),
  } as const;

  return (
    <AccessTokenStyle>
      <Modal
        visible={!!modalTitle}
        onCancel={handleClose}
        title={modalTitle}
        footer={null}
        {...MODAL_PROPS}
      />
      <div className="AccessToken-GenerateTokenModal-ContentWrapper">
        <div className="AccessToken-GenerateTokenModal-Description">
          The created access token is only going to be shown here once, so be sure to copy it to
          your clipboard:
        </div>
        <div className="AccessToken-GenerateTokenModal-Token">
          <div
            className="AccessToken-GenerateTokenModal-TokenText"
            aria-label="copy access token value"
          >
            {accessToken}
            <CopyOutlined
              role="button"
              aria-label="copy access token value"
              className="AccessToken-ButtonWrapper-CopyIcon"
              onClick={handleCopyToken}
            />
          </div>
        </div>
        <div className="AccessToken-GenerateTokenModal-Buttons-Wrapper">
          <Button onClick={handleCopyToken} className="Button--Blue">
            Copy
          </Button>
        </div>
      </div>
    </AccessTokenStyle>
  );
};

export default GenerateTokenDisplayModal;
