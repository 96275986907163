import { Coords, PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { usePreviewSelect } from 'helpers/hooks/usePreviewSelect';
import { meanBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAP_DEFAULTS } from 'screens/MapWrapper/constants';
import { boundariesRequested } from 'state/slices/boundariesSlice';
import { getAllMedia } from 'state/slices/media/mediaThunks';
import { selectAllMedia, selectAllMediaRequestStatus } from 'state/slices/media/selectors';
import { RequestStatus } from 'state/slices/media/types';
import { AppDispatch, RootState } from 'state/store';
import BoundingBox, { BoundingBoxFeature } from 'types/BoundingBox';
import { formatPathName } from './helpers';
import ProjectCategoryMap from './ProjectCategory/ProjectCategoryMap';
import { ZOOM_BY_PROJECT } from './ProjectCategory/ProjectCategoryMap/constants';
import {
  createBoundingBoxFeature,
  mapFeatureToBoundingBox,
} from './ProjectCategory/ProjectCategoryMap/helpers';
import { Metadata } from './ProjectCategory/types';

type ProjectAllMediaMapProps = {
  projectId: string;
  pin: Coords | undefined;
  projectType: PROJECT_TYPES | undefined;
};

export const ProjectAllMediaMap = ({ projectId, pin, projectType }: ProjectAllMediaMapProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const allMediaRequestStatus = useSelector((state: RootState) =>
    selectAllMediaRequestStatus(state, projectId)
  );
  const projectMedias = useSelector((state: RootState) => selectAllMedia(state, projectId));
  const { boundaries: savedBoundaries } = useSelector((state: RootState) => state.boundaries);
  const mediasWithLocation = projectMedias.filter(({ geolocation }) => geolocation);
  const [popup, setPopup] = useState<Metadata | null>(null);
  const [features, setFeatures] = useState<{
    type: string;
    features: [BoundingBoxFeature];
  } | null>(null);
  const [boundaries, setBoundaries] = useState<{
    projectId: string;
    boundingBox: BoundingBox;
  } | null>(null);

  const isAllMediasLoading = allMediaRequestStatus.status === RequestStatus.inProgress;

  const { selectedImage, setSelectedImage } = usePreviewSelect({
    mediaItems: mediasWithLocation,
    isMediaLoading: isAllMediasLoading,
  });

  useEffect(() => {
    if (projectId) {
      dispatch(
        getAllMedia({
          projectId: projectId,
          withFileExtension: false,
        })
      );
      dispatch(boundariesRequested(projectId));
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    if (savedBoundaries) {
      setFeatures(createBoundingBoxFeature(savedBoundaries));
      setBoundaries(savedBoundaries);
    }
  }, [savedBoundaries]);

  const latitude =
    meanBy(mediasWithLocation, ({ geolocation }) => geolocation?.latitude ?? 0) || undefined;
  const longitude =
    meanBy(mediasWithLocation, ({ geolocation }) => geolocation?.longitude ?? 0) || undefined;

  const projectTypeForZoom =
    projectType === PROJECT_TYPES.LARGE ? PROJECT_TYPES.LARGE : PROJECT_TYPES.SMALL;

  const updateOrCreateBoundaries = useCallback(
    (boundingBox: BoundingBoxFeature) => {
      const bounds: { boundingBox: BoundingBox; projectId: string } = mapFeatureToBoundingBox(
        boundingBox,
        projectId
      );
      setBoundaries(bounds);
    },
    [projectId]
  );

  const hasMediaToShow = mediasWithLocation.length || isAllMediasLoading;

  return (
    <ProjectCategoryMap
      pin={hasMediaToShow ? undefined : pin}
      allMedia={mediasWithLocation}
      metadata={mediasWithLocation.map((media) => ({
        id: media.id,
        fileName: media.originalFilenameWithoutExtension + media.originalFileExtension,
        geolocation: media.geolocation ?? { latitude: 0, longitude: 0 },
        isUploaded: true,
        thumbnailUrl: media.thumbnailUrl ?? '',
        data: {
          link: `albums/${formatPathName(media?.mediaCategory?.categoryName ?? 'All Photos')}?preview=${media.id}`,
        },
      }))}
      popup={popup}
      selectedMarkers={[]}
      setPopup={setPopup}
      disableBoundingBox={true}
      selectedImagePreview={selectedImage}
      setSelectedImagePreview={setSelectedImage}
      boundaries={hasMediaToShow ? boundaries : null}
      droneImagesMapViewport={{
        ...MAP_DEFAULTS,
        zoom: ZOOM_BY_PROJECT[projectTypeForZoom],
        latitude: latitude ?? pin?.latitude,
        longitude: longitude ?? pin?.longitude,
      }}
      features={features}
      setFeatures={setFeatures}
      updateOrCreateBoundaries={updateOrCreateBoundaries}
      projectType={projectType}
      imagePreviews={Object.fromEntries(
        mediasWithLocation.map((media) => [media.id, media.thumbnailUrl ?? ''])
      )}
    />
  );
};
