import { ImagePreviewer, ImageSelectionProvider } from '@cpm/scanifly-shared-components';
import { SurveyMedia } from '@cpm/scanifly-shared-data';
import { ALL_PHOTOS_NAME, ALL_PHOTOS_URL_ENCODED } from 'helpers/constants/categories';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { formatNameFromPath } from 'screens/Albums/helpers';
import { selectAllMedia } from 'state/slices/media';
import { getAllMedia } from 'state/slices/media/mediaThunks';
import { projectMediasRequested } from 'state/slices/mediasSlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: black;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 9.4rem);
`;

const AlbumImageViewer = () => {
  const dispatch: AppDispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const imageId = searchParams.get('preview');
  const { projectId, categoryName } = useParams<{ projectId: string; categoryName: string }>();

  const isAllPhotosCategory =
    categoryName === ALL_PHOTOS_NAME || categoryName === ALL_PHOTOS_URL_ENCODED;

  const category = formatNameFromPath(categoryName ?? '');

  const projectMedia = useSelector((state: RootState) => state.medias?.projectMedias ?? null);
  const allMedias = useSelector((state: RootState) => selectAllMedia(state, projectId));

  const activeProjectMedia = projectMedia?.find(({ id }) => id === imageId) ?? null;
  const activeAllMedias = allMedias?.find(({ id }) => id === imageId) ?? null;

  const history = useHistory();

  useEffect(() => {
    if (projectId) {
      dispatch(projectRequested(projectId));
      if (isAllPhotosCategory) {
        dispatch(
          getAllMedia({
            projectId,
            withFileExtension: false,
          })
        );
      } else {
        dispatch(
          projectMediasRequested({
            projectId,
            mediaCategoryName: category,
            withFileExtension: false,
          })
        );
      }
    }
  }, [category, dispatch, isAllPhotosCategory, projectId]);

  const handleCloseImagePreviewer = () => {
    history.goBack();
  };

  if (!imageId) {
    handleCloseImagePreviewer();
    return null;
  }

  return (
    <MainContainer>
      <Wrapper>
        <ImageSelectionProvider
          album={(isAllPhotosCategory ? allMedias : projectMedia) as SurveyMedia[]}
          initialData={{
            handleClose: handleCloseImagePreviewer,
            selectedImage: isAllPhotosCategory
              ? (activeAllMedias as SurveyMedia)
              : activeProjectMedia,
          }}
        >
          <ImagePreviewer
            selectedImage={isAllPhotosCategory ? activeAllMedias : activeProjectMedia}
          />
        </ImageSelectionProvider>
      </Wrapper>
    </MainContainer>
  );
};

export default AlbumImageViewer;
