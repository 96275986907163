export const fetchProjectMediaUrl = (id: string) => `/medias/${id}`;
export const fetchProjectMediasUrl = (projectId: string, withFileExtension: boolean = true) =>
  `/medias/project/${projectId}?withFileExtension=${withFileExtension}`;
export const fetchAllMediasUrl = (projectId: string, withFileExtension: boolean = true) =>
  `/medias/project/all/${projectId}?withFileExtension=${withFileExtension}`;
export const deleteProjectMediasUrl = () => '/medias/many';

export const fetchCategoryMediaUrl = (id: string) => `/surveyMedias/${id}`;
export const downloadCategoryMediaUrl = (id: string) => `surveyMedias/${id}/download-proxy`;
export const fetchCategoryMediasUrl = (
  projectId: string,
  withFileExtension: boolean = true,
  categoryName?: string
) =>
  `/surveyMedias/project/${projectId}?mediaCategoryName=${categoryName}&withFileExtension=${withFileExtension}`;
export const fetchCategoryMediasByIdUrl = (projectId: string, categoryId: string) =>
  `surveyMedias/project/${projectId}?mediaCategoryId=${categoryId}`;
export const deleteCategoryMediasUrl = () => '/surveyMedias/many';
