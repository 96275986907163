import { isGeneralBucket } from '@cpm/scanifly-shared-data/lib/util/mediaUtils';
import React, { useState } from 'react';

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  fallbackSrc: string;
  alt: string;
  src: string;
};

export const ImageFallback = ({ src, alt, fallbackSrc, ...rest }: Props) => {
  const [imgSrc, setImgSrc] = useState(src);
  return (
    <img
      alt={alt}
      src={imgSrc}
      crossOrigin={isGeneralBucket(imgSrc) ? undefined : 'anonymous'}
      onError={() => {
        if (imgSrc !== fallbackSrc) setImgSrc(fallbackSrc);
      }}
      {...rest}
    />
  );
};
