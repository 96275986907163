import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { MediaItem, PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { UppyFile } from '@uppy/core';

import { RootState } from 'state/store';

import { AREA_LIMITS } from 'helpers/constants/areaLimitsInSqFt';
import {
  PROJECT_TYPE_IMAGE_LIMITS,
  SCANIFLY_ADMIN_IMAGE_LIMITS,
} from 'helpers/constants/droneImageLimits';
import usePermissions from 'helpers/hooks/usePermissions';
import { mapAreaToProjectType } from 'helpers/utils/mapAreaToProjectType';
import { squareMetersToFeetConverter } from 'helpers/utils/metricConversions';
import { FileMetaData } from 'helpers/utils/uppy/types';

type UseUploadProjectValidationsProps = {
  projectMedias: (MediaItem | UppyFile<FileMetaData>)[];
  projectType: PROJECT_TYPES | null;
  boundaries: any;
};

export default function useUploadProjectValidations({
  projectMedias,
  projectType,
  boundaries,
}: UseUploadProjectValidationsProps) {
  const [isSaveAllowed, setIsSaveAllowed] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { project } = useSelector((state: RootState) => state.project);

  const { isScaniflyAdmin } = usePermissions();

  const numberOfRemoteFiles = useMemo(
    () =>
      projectMedias.reduce(
        (count, currentValue) => ((currentValue as UppyFile).isRemote ? count + 1 : count),
        0
      ),
    [projectMedias]
  );

  useEffect(() => {
    if (!projectMedias?.length) {
      setIsSaveAllowed(false);
      return setErrorMessage('Drone images are required before uploading your project');
    }
    if (!boundaries?.boundingBox || !projectType) {
      setIsSaveAllowed(false);
      return setErrorMessage(
        'Project type and bounding box must be set before uploading your project'
      );
    }

    const { area } = boundaries?.boundingBox || { area: 0 };

    const areaInSquareFeet = squareMetersToFeetConverter(area);

    if (areaInSquareFeet < AREA_LIMITS.min || areaInSquareFeet > AREA_LIMITS.max) {
      setIsSaveAllowed(false);
      return setErrorMessage(
        `Bounding box is too ${
          areaInSquareFeet < AREA_LIMITS.min ? PROJECT_TYPES.SMALL : PROJECT_TYPES.LARGE
        }. Please adjust the bounding box size.`
      );
    }

    const newProjectType = mapAreaToProjectType(area);
    if (!newProjectType) {
      throw new Error('Invalid project type');
    }
    const imageLimits = !isScaniflyAdmin
      ? // @ts-ignore
        PROJECT_TYPE_IMAGE_LIMITS[newProjectType]
      : SCANIFLY_ADMIN_IMAGE_LIMITS;

    if (!(projectMedias.length >= imageLimits.min && projectMedias.length <= imageLimits.max)) {
      setIsSaveAllowed(false);
      return setErrorMessage(
        `Please upload between ${imageLimits.min} and ${imageLimits.max} drone images.`
      );
    }

    if (numberOfRemoteFiles > 250) {
      setIsSaveAllowed(false);
      return setErrorMessage(
        `Unable to upload more than 250 images from Google Drive at this time.`
      );
    }

    // TODO: disabling this check until we figure out why it's not working

    //if (currentUser?.foldersAccess?.length === 0) {
    //  setIsSaveAllowed(false);
    //  return setErrorMessage(
    //    'Please contact your Account Administrator to upload this project.'
    //  );
    //}

    if ((currentUser?.foldersAccess?.length ?? 0) > 0 && !project?.folder) {
      setIsSaveAllowed(false);
      return setErrorMessage('Please select a folder for this project.');
    }

    setIsSaveAllowed(true);
    setErrorMessage('');
  }, [
    boundaries,
    currentUser?.foldersAccess?.length,
    isScaniflyAdmin,
    projectMedias,
    project?.folder,
    projectType,
    numberOfRemoteFiles,
  ]);

  return { isSaveAllowed, errorMessage };
}
