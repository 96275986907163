import { Dispatch, SetStateAction } from 'react';

import { MediaItem } from '@cpm/scanifly-shared-data';
import { UploadedUppyFile } from '@uppy/core';
import { Note } from 'types';

type EditNoteModalProps = {
  filesToRender: (MediaItem | UploadedUppyFile<any, any>)[];
  handleUpload: () => void;
  isWaitingToFetchNote: boolean;
  isUploadModalVisible: boolean;
  onClose: () => void;
  selectedNote: Note;
  setDeletedFiles: Dispatch<SetStateAction<string[]>>;
  value: string;
  updateNote: (text: string) => Promise<void>;
  setValue: Dispatch<SetStateAction<string>>;
};

enum FormFields {
  note = 'note',
  images = 'images',
}

type FormValues = {
  note: string;
  images: string[];
};

const InitialValues = {
  note: '',
  images: [],
};

export { FormFields, InitialValues };
export type { EditNoteModalProps, FormValues };
