import { memo, useRef } from 'react';

import cn from 'classnames';

import { MediaItem } from '@cpm/scanifly-shared-data';
import Thumbnail from './Thumbnail';
import './Thumbnail.scss';
import { ThumbnailListProps } from './types';

const ThumbnailList = ({
  selectedThumbnail,
  thumbnails,
  isDraft,
  isDroneImages,
  isListView,
  handleSelectImage,
  handleRenderMap,
  handleCheck,
  checkedThumbnails,
  selectedImage,
  imagePreviews,
  isDroneDataAvailable,
  droneDataScoreAccess,
}: ThumbnailListProps) => {
  const thumbnailListRef = useRef<HTMLDivElement>(null);
  const isScrollbarVisible =
    thumbnailListRef.current &&
    thumbnailListRef.current?.scrollHeight > thumbnailListRef.current?.clientHeight;

  return (
    <div
      ref={thumbnailListRef}
      className={cn('Thumbnail-List', {
        'Thumbnail-List--Scroll': isScrollbarVisible,
        'Thumbnail-List--DroneImages': isDroneImages && isDraft,
        'Thumbnail-List--Empty': isDraft && !thumbnails.length,
        'Thumbnail-List--GridView': !isListView,
        'Thumbnail-List--Processed':
          isDroneImages && !isDraft && isDroneDataAvailable && droneDataScoreAccess,
        'Thumbnail-List--Processed--DroneDataUnavailable':
          isDroneImages && !isDraft && !isDroneDataAvailable && droneDataScoreAccess,
        'Thumbnail-List--Processed--WithoutDroneDataScore':
          isDroneImages && !isDraft && !droneDataScoreAccess,
      })}
    >
      {thumbnails.map((thumbnail, i) => (
        <div
          className={cn('Thumbnail', {
            'Thumbnail--List': isListView,
          })}
          key={thumbnail.id}
        >
          <Thumbnail
            isListView={isListView}
            key={thumbnail.id}
            preview={imagePreviews[thumbnail.id]}
            mediaItem={thumbnail}
            index={i}
            handleSelectImage={handleSelectImage}
            handleRenderMap={handleRenderMap}
            handleCheck={handleCheck}
            checked={checkedThumbnails.includes(thumbnail.id)}
            isActiveImage={selectedImage?.id === thumbnail.id && !selectedThumbnail}
            popup={selectedThumbnail?.id === thumbnail.id && !selectedImage}
            projectThumbnail={isDroneImages && thumbnails[0].id === thumbnail.id}
            isLoading={!(thumbnail as MediaItem).fileId && !isDroneImages}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(ThumbnailList);
