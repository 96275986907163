import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import MapGL, { MapEvent, MapRef, Source, WebMercatorViewport } from 'react-map-gl';
import { useLocation } from 'react-router';

import { FeatureCollection } from '@turf/helpers';
import { Button } from 'antd';
import cn from 'classnames';
import * as GeoJSON from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapPin from 'screens/MapWrapper/MapPin';
import { Project } from 'types';

import { MapStyleControl } from 'components';

import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as FullscreenIcon } from 'assets/icons/fullscreen-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';

import Clusters from './Clusters';
import { MAP_DEFAULTS, MAP_STYLES, MAP_THEMES, MAP_ZOOM } from './constants';
import { getMarker } from './helpers';
import MapWrapperPopup from './MapPopup/MapPopup';
import './MapWrapper.scss';
import { MapViewport, MapWrapperProps, Pin } from './types';

const getBoundsForPins = (pins: Omit<Pin, 'id'>[]) => {
  const pinsLong = pins.map((pin) => Number(pin.location.longitude));
  const pinsLat = pins.map((pin) => Number(pin.location.latitude));
  const cornersLongLat: [[number, number], [number, number]] = [
    [Math.min(...pinsLong), Math.min(...pinsLat)],
    [Math.max(...pinsLong), Math.max(...pinsLat)],
  ];
  const viewport = new WebMercatorViewport({
    width: 800,
    height: 600,
  }).fitBounds(cornersLongLat, { padding: 200 });
  const { longitude, latitude } = viewport;

  return { longitude, latitude };
};

const MapWrapper = ({
  children,
  pins = [],
  checkedPins = [],
  allProjects = [],
  clustered = false,
  resetViewport = false,
  setResetViewport = () => null,
  handleBoundsChange = () => null,
  hidden = false,
  hiddenVisibility = false,
  isClickable = true,
  withHideMapButton = false,
  withToggleFullscreen = false,
  isMapOnly = false,
}: MapWrapperProps) => {
  const location = useLocation();
  const [viewport, setViewport] = useState<MapViewport>(MAP_DEFAULTS);
  const [isFullscreen, toggleFullscreen] = useToggle();
  const [isMapHidden, toggleMapHidden] = useToggle(true);
  const [selectedMapStyle, setSelectedMapStyle] = useState<string>(MAP_STYLES.satellite);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isFirstPinRender, setIsFirstPinRender] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isNotes = location.pathname.includes('/comments');
  const isProjectInfo = location.pathname.includes('/project-info');
  const isScaniflyInfo = location.pathname.includes('/scanifly-info');
  const isProjectCategory = location.pathname.includes('/albums');
  const isDesigns = location.pathname.includes('/designs');
  const mapRef = useRef<MapRef>(null);

  const handleFullScreenClick: MouseEventHandler<HTMLElement> = (event) => {
    if (isFullscreen) {
      event.currentTarget.blur();
    }
    toggleFullscreen();
    setViewport({ ...viewport, width: '100%' });
  };

  const handleHideMapClick: MouseEventHandler<HTMLElement> = (event) => {
    if (isFullscreen) {
      toggleFullscreen();
    } else if (isMapHidden) {
      event.currentTarget.blur();
    }
    toggleMapHidden();
  };

  const handleMapClick = (event: MapEvent) => {
    const map = mapRef.current?.getMap();
    if (!map) {
      return;
    }
    if (event.features?.length) {
      const feature = event.features[0];
      if (feature.layer?.id === 'clusters' && map.getLayer('clusters')) {
        const clusterId = feature.properties.cluster_id;
        const mapboxSource = map.getSource('feature');
        mapboxSource.getClusterExpansionZoom(clusterId, (err: any, zoom: number) => {
          if (err) {
            return;
          }

          setViewport({
            ...viewport,
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1],
            zoom: zoom,
          });
        });
      } else {
        return setSelectedProject(
          allProjects.filter((project) => project.id === feature.properties.id)[0]
        );
      }
    }
    if (selectedProject) {
      setSelectedProject(null);
    }
  };

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      map.once('styledata', () => {
        map.once('styleimagemissing', () => {
          if (!map.hasImage('pulsing-dot')) {
            map.addImage('pulsing-dot', getMarker(true, mapRef, selectedMapStyle), {
              pixelRatio: 2,
            });
          }
          if (!map.hasImage('dot')) {
            map.addImage('dot', getMarker(false, mapRef, selectedMapStyle), { pixelRatio: 2 });
          }
        });
      });
    }
  }, [clustered, selectedMapStyle]);

  useEffect(() => {
    if (!isFirstPinRender && resetViewport && allProjects?.length) {
      setResetViewport(false);
      const bounds = getBoundsForPins(pins.map((pin) => ({ location: pin.location })));
      setViewport((viewport) => ({ ...viewport, ...bounds }));
    }
  }, [isFirstPinRender, allProjects, pins, resetViewport, setResetViewport]);

  useEffect(() => {
    if (isMapOnly) {
      return;
    }

    if (isClickable && pins?.length > 0 && isFirstPinRender) {
      setIsFirstPinRender(false);
      const bounds = getBoundsForPins(pins);
      setViewport((viewport) => ({ ...viewport, ...bounds }));
    } else if (!isClickable && pins?.length === 1) {
      setViewport((viewport) => ({
        ...viewport,
        longitude: Number(pins[0].location.longitude),
        latitude: Number(pins[0].location.latitude),
        zoom: MAP_ZOOM,
      }));
    }
  }, [pins, isClickable, isMapOnly, isFirstPinRender]);

  const getProjectLocations = (pins: Pin[]): FeatureCollection<GeoJSON.Geometry> => {
    return {
      type: 'FeatureCollection',
      features: pins.map((pin) => ({
        type: 'Feature',
        properties: {
          id: pin.id,
          selected:
            checkedPins?.includes(pin.id) || selectedProject?.id === pin.id ? 'true' : 'false',
        },
        geometry: {
          type: 'Point',
          coordinates: [pin.location.longitude, pin.location.latitude],
        },
      })),
    };
  };

  const handleChange = () => {
    const mapGL = mapRef?.current?.getMap();
    if (pins?.length || isMapOnly) {
      const bounds = mapGL?.getBounds();
      handleBoundsChange(bounds);
    }
  };

  const handleLoadMap = () => {
    setIsLoaded(true);
  };

  return (
    <div className="MapWrapper">
      <div className="MapWrapper-Buttons">
        {withToggleFullscreen && (
          <Button
            className={cn('Button--Filter MapWrapper-Button', { 'Button--Active': isFullscreen })}
            onClick={handleFullScreenClick}
            tabIndex={0}
            disabled={isMapHidden}
            aria-disabled={isMapHidden}
          >
            Full Screen
            <FullscreenIcon />
          </Button>
        )}
        {withHideMapButton && (
          <Button
            className={cn('Button--Filter MapWrapper-Button', { 'Button--Active': isMapHidden })}
            onClick={handleHideMapClick}
            tabIndex={0}
            disabled={isMapOnly}
            aria-disabled={isMapOnly}
          >
            {isMapHidden ? 'Show Map' : 'Hide Map'}
            <LogoutIcon className={cn({ 'MapWrapper-Icon--Inverted': isMapHidden })} />
          </Button>
        )}
      </div>
      <div
        className={cn('MapWrapper-Content', {
          'MapWrapper-Content--Hidden': !isMapHidden && isFullscreen,
          'MapWrapper-Content--MapHidden': isMapHidden,
          'MapWrapper-Content--ProjectStep':
            isNotes || isProjectInfo || isScaniflyInfo || isProjectCategory || isDesigns,
        })}
        aria-hidden={!isMapHidden && isFullscreen}
      >
        {children}
      </div>
      <div
        className={cn('MapWrapper-Mapbox', {
          'MapWrapper-Mapbox--Hidden': isMapHidden || hidden,
          'MapWrapper-Mapbox--Hidden-Visibility': hiddenVisibility,
        })}
        aria-hidden={isMapHidden}
      >
        {!isMapHidden && (
          <MapGL
            {...viewport}
            onViewportChange={(nextViewport: MapViewport) => {
              handleChange();
              return setViewport({
                ...nextViewport,
                width: '100%',
                height: '100%',
              });
            }}
            onLoad={handleLoadMap}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            interactiveLayerIds={
              // @ts-ignore
              clustered && isLoaded
                ? [Clusters.clusterLayerId, Clusters.unclusteredPointLayerId]
                : []
            }
            // @ts-ignore
            mapStyle={MAP_THEMES[selectedMapStyle]}
            onClick={handleMapClick}
            ref={mapRef}
            scrollZoom={{ speed: 5, smooth: true }}
          >
            {selectedProject && <MapWrapperPopup selectedProject={selectedProject} />}
            {clustered && (
              <Source
                id="feature"
                type="geojson"
                data={getProjectLocations(pins)}
                cluster={true}
                clusterMaxZoom={12}
                clusterRadius={52}
              >
                <Clusters mapStyle={selectedMapStyle} />
              </Source>
            )}
            {!clustered &&
              pins.map(({ id, location, isChecked }) => (
                <MapPin
                  key={id}
                  longitude={Number(location.longitude)}
                  latitude={Number(location.latitude)}
                  isChecked={isChecked}
                  id={id}
                  isClickable={isClickable}
                  //handleClick={handlePinClick}
                  isSatelliteView={selectedMapStyle === MAP_STYLES.satellite}
                />
              ))}
            <MapStyleControl mapStyle={selectedMapStyle} setMapStyle={setSelectedMapStyle} />
          </MapGL>
        )}
      </div>
    </div>
  );
};

export default MapWrapper;
