import { formatPathName } from 'screens/Albums/helpers';

export const rootRoute = () => '/';

// Sign up
export const signUpMainInformationRoute = () => '/sign-up-main-information';
export const signUpBillingInformationRoute = () => '/sign-up-billing-information';

// Account
export const accountMenuRoute = () => '/account';
export const accountProfileRoute = () => '/account/profile';
export const accountCompanyInfoRoute = () => '/account/company-info';
export const accountCompanyChecklistTemplateEditorRoute = (templateId: string) =>
  `/company-info/edit-checklist-template/${templateId}`;
export const accountChecklistTemplateManagerRoute = () => `/company-info/checklist-manager`;
export const accountLoginSecurityRoute = () => '/account/login-security';
export const accountPaymentsInvoicesRoute = () => '/account/payments-invoices';
export const account3dSettingsRoute = () => '/account/scanifly-3dsettings';
export const accountAccessTokensRoute = () => `/account/access-tokens`;

// Reset password
export const resetPasswordRoute = () => '/reset-password';
export const setNewPasswordRoute = () => '/set-password';

// Team
export const teamListRoute = () => '/team-list';

// Integrations
export const integrationsRoute = () => '/integrations';

// Projects
export const projectsListRoute = () => '/projects-list';
export const tokenValidationRoute = () => '/token-not-valid';
export const newProjectOverviewRoute = () => '/new-project/overview';
export const newProjectCustomerInfoRoute = () => '/new-project/customer-info';
export const newProjectConsumptionDataRoute = () => '/new-project/consumption-data';
export const draftProjectOverviewRoute = (projectId?: string) =>
  `/projects/new/${projectId}/overview`;
export const draftProjectCustomerInfoRoute = (projectId?: string) =>
  `/projects/new/${projectId}/customer-info`;
export const draftProjectConsumptionDataRoute = (projectId?: string) =>
  `/projects/new/${projectId}/consumption-data`;
export const draftProjectInfo = (projectId?: string) => `/projects/new/${projectId}/project-info`;
export const draftProjectSiteDataRoute = (projectId?: string) =>
  `/projects/new/${projectId}/site-data`;
export const draftNotesRoute = (projectId?: string) => `/projects/new/${projectId}/comments`;
export const draftChecklistRoute = (projectId?: string) => `/projects/new/${projectId}/checklists`;
export const draftProjectCategoriesRoute = (projectId?: string) =>
  `/projects/new/${projectId}/albums`;
export const draftProjectCategoryRoute = (projectId: string, categoryName: string) =>
  `/projects/new/${projectId}/albums/${formatPathName(categoryName)}`;
export const projectOverviewRoute = (projectId?: string) => `/projects/${projectId}/overview`;
export const projectCustomerInfoRoute = (projectId?: string) =>
  `/projects/${projectId}/overview/customer-info`;
export const projectConsumptionDataRoute = (projectId?: string) =>
  `/projects/${projectId ?? ''}/overview/consumption-data`;
export const projectInfoRoute = (projectId?: string) =>
  `/projects/${projectId}/overview/project-info`;
export const projectSiteDataRoute = (projectId?: string) => `/projects/${projectId}/site-data`;
export const projectCategoriesRoute = (projectId?: string) =>
  `/projects/${projectId}/site-data/albums`;
export const projectNotesRoute = (projectId?: string) => `/projects/${projectId}/comments`;
export const projectCategoryRoute = (projectId: string, categoryName: string) =>
  `/projects/${projectId}/site-data/albums/${formatPathName(categoryName)}`;
export const projectCategoryAlbumPreviewerRoute = (projectId: string, categoryName: string) =>
  `/projects/${projectId}/site-data/albums/${categoryName}`;
export const projectDesignsRoute = (projectId?: string) => `/projects/${projectId ?? ''}/designs`;
export const draftProjectDesignsRoute = (projectId?: string) =>
  `/projects/new/${projectId}/designs`;

// S3D
export const s3dV2 = (projectId: string, designId: string) =>
  `/projects/${projectId}/${designId}/viewer`;
export const s3dV3 = (projectId: string, designId: string) =>
  `/projects/${projectId}/${designId}/v3`;
export const s3dVSelect = (projectId: string, designId: string) =>
  `/projects/${projectId}/${designId}/vSelect`;

// Scanifly Admin
export const scaniflyAdminMenuRoute = () => `/scanifly-admin`;
export const scaniflyAdminAccountManagerRoute = () => `/scanifly-admin/account-manager`;
export const scaniflyAdminAccountingRoute = (companyId?: string) =>
  `/scanifly-admin/accounting/${companyId ?? ''}`;
// Fetches all credit transactions for a single company (Admin Console > Account Manager > Credit History)
export const scaniflyAdminCompanyCreditHistoryRoute = (companyId: string) =>
  `/scanifly-admin/credit-history/${companyId ?? ''}`;
// Fetches all credit transactions (Admin Console > Credit History)
export const scaniflyAdminAllCreditHistoryRoute = () => `/scanifly-admin/credit-history/all`;
export const scaniflyAdminAccountingTableRoute = () => `/scanifly-admin/accounting/table`;
export const scaniflyAdminUsersRoute = () => `/scanifly-admin/user-manager`;
export const scaniflyAdminReferenceMediaRoute = () => `/scanifly-admin/reference-media`;
export const scaniflyAdminUsersCustomerRoute = (companyId?: string) =>
  `/scanifly-admin/user-manager/${companyId ?? ''}`;
export const scaniflyAdminProjectsByUserRoute = (userId?: string) =>
  `/scanifly-admin/project-manager/user/${userId ?? ''}`;
export const scaniflyAdminCustomerSupportUploadRoute = () =>
  `/scanifly-admin/customer-support-upload`;
export const scaniflyAdminProjectsRoute = () => `/scanifly-admin/project-manager`;
export const scaniflyAdminAccessTokensRoute = () => `/scanifly-admin/access-tokens`;
export const scaniflyAdminDesignServicesQueueRoute = () => `/scanifly-admin/design-services-queue`;
export const scaniflyAdminProjectsCustomerRoute = (companyId: string) =>
  `/scanifly-admin/project-manager/company/${companyId ?? ''}`;
export const scaniflyAdminNewProjectOverviewRoute = () => '/scanifly-admin/new-project/overview';
export const scaniflyAdminNewProjectCustomerInfoRoute = () =>
  '/scanifly-admin/new-project/customer-info';
export const scaniflyAdminNewProjectConsumptionDataRoute = () =>
  '/scanifly-admin/new-project/consumption-data';
export const scaniflyAdminNewProjectScaniflyInfoRoute = () =>
  `/scanifly-admin/new-project/scanifly-info`;
export const scaniflyAdminDraftOverviewRoute = (projectId: string) =>
  `/scanifly-admin/projects/new/${projectId}/overview`;
export const scaniflyAdminDraftCustomerInfoRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/customer-info`;
export const scaniflyAdminDraftConsumptionDataRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/consumption-data`;
export const scaniflyAdminDraftProjectInfoRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/project-info`;
export const scaniflyAdminDraftScaniflyInfoRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId ?? ''}/scanifly-info`;
export const scaniflyAdminDraftSiteDataRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/site-data`;
export const scaniflyAdminDraftNotesRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/comments`;
export const scaniflyAdminDraftProjectCategoriesRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/albums`;
export const scaniflyAdminDraftProjectCategoryRoute = (projectId: string, categoryName: string) =>
  `/scanifly-admin/projects/new/${projectId}/albums/${formatPathName(categoryName)}`;
export const scaniflyAdminDraftProjectDesignsRoute = (projectId?: string) =>
  `/scanifly-admin/projects/new/${projectId}/designs`;
export const scaniflyAdminProjectOverviewRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/overview`;
export const scaniflyAdminProjectCustomerInfoRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/overview/customer-info`;
export const scaniflyAdminProjectConsumptionDataRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/overview/consumption-data`;
export const scaniflyAdminProjectProjectInfoRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/overview/project-info`;
export const scaniflyAdminProjectScaniflyInfoRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId ?? ''}/scanifly-info`;
export const scaniflyAdminProjectSiteDataRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/site-data`;
export const scaniflyAdminProjectNotesRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/comments`;
export const scaniflyAdminProjectCategoriesRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/albums`;
export const scaniflyAdminProjectCategoryRoute = (projectId: string, categoryName: string) =>
  `/scanifly-admin/projects/${projectId}/albums/${formatPathName(categoryName)}`;
export const scaniflyAdminProjectDesignsRoute = (projectId?: string) =>
  `/scanifly-admin/projects/${projectId}/designs`;
export const scaniflyAdminChecklistManagerRoute = (companyId: string) =>
  `/scanifly-admin/company-info/checklist-admin-manager/${companyId}`;
export const projectChecklistRoute = (projectId?: string) =>
  `/projects/${projectId ?? ''}/site-data/checklists`;

// Credits
// Fetches all credit transactions of a company, only visible to company admins (Credit History)
// Other 2 credit routes are under scanifly admin routes
export const creditHistoryAdminQueueRoute = () => `/credit-history`;

// Design Services
export const designServicesProviderQueueRoute = () => '/design-services-queue';
export const designServicesCompanyQueueRoute = () => '/designServices/queue';
export const designServicesLandingRoute = (projectId: string) => `/designServices/${projectId}`;

export const orderDesignRoute = (projectId?: string, step = 0) =>
  `/designServices/order/${projectId}/${step}`;
export const orderDesignDashRoute = (projectId?: string, step = 0) =>
  `/design-services/order/${projectId}/${step}`;

export const upgradeDesignRoute = (projectId?: string, step = '0') =>
  `/designServices/upgrade/${projectId}/${step}`;

export const designStatusRoute = (projectId?: string, step = '0') =>
  `/designServices/status/${projectId}/${step}`;

// Maintenance Report
export const maintenanceReportRoute = (projectId?: string) =>
  `/maintenance-report/project/${projectId}`;

export const maintenanceReportPublicDisplayRoute = (reportId: string) =>
  `/maintenance-report/${reportId}`;
