import { ACCESS } from 'helpers/constants/access';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';

import { useLocation } from 'react-router-dom';
import AlbumImageViewer from '../AlbumView/AlbumImageViewer/AlbumImageViewer';
import { AlbumView } from '../AlbumView/AlbumView';
import ProjectCategory from './ProjectCategory';
import OldProjectCategory from './ProjectCategory.old';

export default function ProjectCategoryToggle({
  isAdminRoute = false,
}: {
  isAdminRoute?: boolean;
}) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isLegacy = searchParams.get('legacy') !== '0';
  const isImagePreview = searchParams.get('preview') !== null;
  const enableProjectCategoryRefactor = useFeatureToggle(ACCESS.ENABLE_PROJECT_CATEGORY_REFACTOR);

  if (!isLegacy) {
    if (isImagePreview) {
      return <AlbumImageViewer />;
    }
    return <AlbumView />;
  }

  if (enableProjectCategoryRefactor) {
    // eslint-disable-next-line
    console.log('Enabling Project Category Refactor...');
    return <ProjectCategory isAdminRoute={isAdminRoute} />;
  }
  return <OldProjectCategory isAdminRoute={isAdminRoute} />;
}
