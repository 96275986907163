import { Dispatch } from 'redux';

import {
  fetchAllMedias,
  fetchCategoryMediasById,
  fetchProjectMedias,
} from 'api/medias/mediasService';
import { selectCategoriesByType } from 'state/slices/mediaCategories';
import { RootState } from 'state/store';
import { MediaCategoryType } from 'types';

import { ALL_MEDIA } from './constants';
import { actions } from './mediaSlice';
import { selectMediaRequestStatus } from './selectors';
import { RequestStatus } from './types';

export const getCategoryMedia =
  ({ projectId, categoryId }: { projectId: string; categoryId: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const mediaRequestStatus = selectMediaRequestStatus(getState(), projectId, categoryId);
    if (mediaRequestStatus.status === RequestStatus.inProgress) return;
    dispatch(actions.CATEGORY_REQUEST_IN_PROGRESS({ projectId, categoryId }));
    const droneMediaCategories = selectCategoriesByType(getState(), MediaCategoryType.droneImages);
    const isDroneMediaRequest = droneMediaCategories.some((cat) => cat.id === categoryId);
    try {
      const response = await (isDroneMediaRequest
        ? fetchProjectMedias(projectId)
        : fetchCategoryMediasById(projectId, categoryId));
      const media = response.data;
      dispatch(actions.CATEGORY_REQUEST_SUCCESSFUL({ projectId, categoryId, media }));
    } catch (err: any) {
      const errorMessage = `Media request for category ${categoryId} failed with message: ${err?.message}`;
      dispatch(actions.CATEGORY_REQUEST_FAILED({ projectId, categoryId, errorMessage }));
    }
  };

export const getAllMedia =
  ({ projectId, withFileExtension }: { projectId: string; withFileExtension?: boolean }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const mediaRequestStatus = selectMediaRequestStatus(getState(), projectId, ALL_MEDIA);
    if (mediaRequestStatus.status === RequestStatus.inProgress) return;
    dispatch(actions.CATEGORY_REQUEST_IN_PROGRESS({ projectId, categoryId: ALL_MEDIA }));
    try {
      const response = await fetchAllMedias(projectId, withFileExtension);
      const media = response.data;
      dispatch(actions.CATEGORY_REQUEST_SUCCESSFUL({ projectId, categoryId: ALL_MEDIA, media }));
    } catch (err: any) {
      const errorMessage = `Media request for category ${ALL_MEDIA} failed with message: ${err?.message}`;
      dispatch(actions.CATEGORY_REQUEST_FAILED({ projectId, categoryId: ALL_MEDIA, errorMessage }));
    }
  };
