import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { downloadCategoryMedia } from 'api/medias/mediasService';
import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { Button, ModalContext, Tooltip } from 'components';
import {
  draftProjectCategoryRoute,
  projectCategoryRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminProjectCategoryRoute,
} from 'helpers/constants/routes';
import zipMedia from 'helpers/utils/zipMedia';
import { useTranslation } from 'react-i18next';
import { selectMediaByClientRefIds } from 'state/slices/media';
import { selectProjectMediaCategories } from 'state/slices/projectMediaCategories';
import { RootState } from 'state/store';
import styled from 'styled-components';
import { ModalMedia } from 'types';

import { isGeneralBucket } from '@cpm/scanifly-shared-data/lib/util/mediaUtils';
import colors from 'helpers/constants/colors';

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerLabel = styled.div`
  font-weight: bold;
  margin-top: 1rem;
`;

const AlbumsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlbumRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: space-between;
`;

const AlbumsSpacer = styled.div`
  flex-grow: 1;
`;

const AlbumsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlbumContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  padding: 0.5rem;
  flex-grow: 1;
`;

const AlbumIcon = styled(CategoryIcon)`
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
`;

const DownloadButtonWrapper = styled.div`
  flex-shrink: 1;
`;

const AnswerInfo = styled.div`
  font-weight: normal;
`;

const MediaAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PlaceHolderThumbnail = styled.div`
  height: 10rem;
  width: 10rem;
  border: solid;
  border-color: ${colors.midGray};
  background-color: ${colors.darkGrayOpacity};
`;

const ClickableThumbnail = styled.div``;

const MediaThumbnail = styled.img`
  height: 10rem;
  width: 10rem;
  border: solid;
  border-color: ${colors.midGray};
`;

type Props = {
  answer: { clientRefIds: string[]; mediaCategoryId: string } | null;
  additionalCategoryIds?: string[];
};

export const MediaAnswer = ({ answer, additionalCategoryIds }: Props) => {
  const location = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const { displayMediaViewModal } = useContext(ModalContext);
  const projectMediaCategories = useSelector(selectProjectMediaCategories);
  const media = useSelector((state: RootState) =>
    selectMediaByClientRefIds(state, answer?.clientRefIds)
  );
  const [mediaDownloading, setMediaDownloading] = useState(false);
  const { uploadedMedia, uploadingMedia } = useMemo(() => {
    const refIds = answer?.clientRefIds ?? [];
    if (refIds.length < 1) return { uploadedMedia: 0, uploadingMedia: 0 };
    const uploadedMedia = refIds.filter((refId) => media[refId] !== undefined);
    return {
      uploadedMedia: uploadedMedia.length,
      uploadingMedia: refIds.length - uploadedMedia.length,
    };
  }, [media, answer?.clientRefIds]);

  const isScaniflyAdminRoute = useMemo(
    () => location.pathname.includes('scaniflyAdmin'),
    [location]
  );
  const isDraft = useMemo(() => location.pathname.includes('projects/new'), [location]);

  const getDraftRoute = useCallback(
    (projectId: string, pathName: string) => {
      return isScaniflyAdminRoute
        ? scaniflyAdminDraftProjectCategoryRoute(projectId, pathName)
        : draftProjectCategoryRoute(projectId, pathName);
    },
    [isScaniflyAdminRoute]
  );

  const getProjectRoute = useCallback(
    (projectId: string, pathName: string) => {
      return isScaniflyAdminRoute
        ? scaniflyAdminProjectCategoryRoute(projectId, pathName)
        : projectCategoryRoute(projectId, pathName);
    },
    [isScaniflyAdminRoute]
  );

  const onOpenAlbum = useCallback(
    (categoryName: string) => {
      const path = isDraft
        ? getDraftRoute(projectId, categoryName)
        : getProjectRoute(projectId, categoryName);
      const navUrl = new URL(window.origin + path);
      window.open(navUrl, '_blank');
    },
    [getDraftRoute, getProjectRoute, isDraft, projectId]
  );

  const onMediaDownload = useCallback(
    async (refId: string) => {
      const mediaItem = media[refId];
      if (!mediaItem) return;
      const imageResponse = await downloadCategoryMedia(mediaItem.id);
      if (imageResponse.status !== 200) return;
      const imageObjectUrl = window.URL.createObjectURL(imageResponse.data);
      const dlLink = document.createElement('a');
      dlLink.style.display = 'none';
      dlLink.href = imageObjectUrl;
      dlLink.download = `${mediaItem.originalFilenameWithoutExtension}${mediaItem.originalFileExtension}`;
      document.body.appendChild(dlLink);
      dlLink.click();
      window.URL.revokeObjectURL(imageObjectUrl);
    },
    [media]
  );

  const onThumbnailClick = useCallback(
    (mediaId: string) => {
      const mediaMap: { [key: string]: ModalMedia } = {};
      for (const mediaItem of Object.values(media)) {
        const refId = mediaItem.clientRefId ?? mediaItem.id;
        mediaMap[refId] = {
          thumbnailUrl: mediaItem.thumbnailUrl ?? '',
          fullSizeUrl: mediaItem.imgUrl ?? mediaItem.thumbnailUrl ?? '',
          name: `${mediaItem.originalFilenameWithoutExtension}${mediaItem.originalFileExtension}`,
          handleDownload: () => onMediaDownload(refId),
        };
      }
      const mediaOrder = answer?.clientRefIds.filter((id) => media[id]);
      displayMediaViewModal({
        mediaMap: mediaMap,
        mediaOrder: mediaOrder ?? [],
        initialSelectedId: mediaId,
      });
    },
    [answer?.clientRefIds, displayMediaViewModal, media, onMediaDownload]
  );

  const mediaDisplay = useMemo(() => {
    if (answer === null) return null;
    return answer.clientRefIds.map((refId) => {
      if (media[refId] && media[refId].thumbnailUrl)
        return (
          <ClickableThumbnail key={refId} onClick={() => onThumbnailClick(refId)}>
            <MediaThumbnail
              crossOrigin={isGeneralBucket(media[refId].thumbnailUrl) ? undefined : 'anonymous'}
              src={media[refId]?.thumbnailUrl}
            />
          </ClickableThumbnail>
        );
      if (media[refId] && media[refId].imgUrl)
        return (
          <ClickableThumbnail key={refId} onClick={() => onThumbnailClick(refId)}>
            <MediaThumbnail
              crossOrigin={
                isGeneralBucket(media[refId].thumbnailUrl ?? '') ? undefined : 'anonymous'
              }
              src={media[refId]?.imgUrl}
            />
          </ClickableThumbnail>
        );
      return <PlaceHolderThumbnail key={refId} />;
    });
  }, [answer, media, onThumbnailClick]);

  const mediaCategories = useMemo(() => {
    if (!answer?.mediaCategoryId) return null;
    const categoryIds = [answer.mediaCategoryId, ...(additionalCategoryIds ?? [])];
    const categories = categoryIds.map((catId) => {
      const cat = projectMediaCategories.find((category) => category.id === catId);
      if (cat) {
        return (
          <AlbumRow key={catId}>
            <AlbumContainer>
              <AlbumIcon />
              {cat.categoryName}
            </AlbumContainer>
            <Tooltip title={t('Checklists.viewAlbum')}>
              <Button
                icon="newTab"
                color={colors.mainBlue}
                label={t('Checklists.openAlbum') ?? ''}
                onClick={() => onOpenAlbum(cat.categoryName)}
              />
            </Tooltip>
          </AlbumRow>
        );
      }
      return (
        <AlbumRow key={catId}>
          <AlbumContainer>{t('Checklists.missingCategory')}</AlbumContainer>
        </AlbumRow>
      );
    });
    if (categoryIds.length < 1) return null;
    return (
      <>
        <AnswerLabel>{t('Checklists.mediaStoredInAlbums')}</AnswerLabel>
        <AlbumsRow>
          <AlbumsContainer>{categories}</AlbumsContainer>
          <AlbumsSpacer />
        </AlbumsRow>
      </>
    );
  }, [answer?.mediaCategoryId, additionalCategoryIds, projectMediaCategories, t, onOpenAlbum]);

  const downloadMedia = useCallback(async () => {
    setMediaDownloading(true);
    const mediaToDownload = (answer?.clientRefIds ?? []).map((refId) => media[refId]);
    const checklistMediaCategory = projectMediaCategories.find(
      (cat) => cat.id === answer?.mediaCategoryId
    );
    // This shouldn't happen but if it does, at least the error should be easy to diagnose.
    const zipName = checklistMediaCategory?.categoryName ?? 'media-category-does-not-exist';
    await zipMedia(mediaToDownload, zipName);
    setMediaDownloading(false);
  }, [answer?.clientRefIds, answer?.mediaCategoryId, media, projectMediaCategories]);

  if (answer === null || (Array.isArray(answer?.clientRefIds) && answer.clientRefIds.length < 1))
    return null;

  return (
    <AnswerWrapper>
      {mediaCategories}
      <AnswerLabel>{t('Checklists.mediaCanBeViewed')}</AnswerLabel>
      <DownloadButtonWrapper>
        <Tooltip bottom title={t('Checklists.downloadImages')}>
          <Button
            disabled={mediaDownloading}
            icon="clouddownload"
            label={t('Checklists.images')}
            color={colors.mainBlue}
            onClick={() => downloadMedia()}
            margin="1rem 0"
          />
        </Tooltip>
      </DownloadButtonWrapper>
      <AnswerInfo>
        {t('Checklists.mediaUploaded', {
          uploaded: `${uploadedMedia}`,
          uploading: `${uploadingMedia}`,
        })}
      </AnswerInfo>
      <MediaAnswerWrapper>{mediaDisplay}</MediaAnswerWrapper>
    </AnswerWrapper>
  );
};
