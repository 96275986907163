import { Button } from 'components';
import colors from 'helpers/constants/colors';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`;

const getButtonColor = (isVisible: boolean, isActive: boolean) => {
  if (isVisible) {
    return colors.lightGray;
  } else if (isActive) {
    return colors.neutralGray;
  }
  return colors.background;
};

export const KebabMenu = ({
  content,
  isActive,
  setOpenMiniMenuId,
  openMiniMenuId,
  imageId,
  onClick,
}: {
  content: JSX.Element;
  isActive: boolean;
  setOpenMiniMenuId: React.Dispatch<React.SetStateAction<string | null>>;
  openMiniMenuId: string | null;
  imageId: string;
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => {
  const isVisible = openMiniMenuId === imageId;
  const handleClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.stopPropagation();
      onClick?.(event);
      if (!isVisible) {
        setOpenMiniMenuId(imageId);
      } else {
        setOpenMiniMenuId(null);
      }
    },
    [imageId, isVisible, onClick, setOpenMiniMenuId]
  );

  const buttonColor = getButtonColor(isVisible, isActive);

  return (
    <Wrapper data-testid="kebab-menu">
      <Button
        icon="kebabMenu"
        padding="0.5rem"
        width="3rem"
        height="3rem"
        color={buttonColor}
        margin="0"
        border="none"
        onClick={handleClick}
      />
      {isVisible ? content : null}
    </Wrapper>
  );
};
