import breakpoints from 'helpers/constants/breakpoints';
import { ALL_PHOTOS_NAME, ALL_PHOTOS_URL_ENCODED } from 'helpers/constants/categories';
import fontSizes from 'helpers/constants/fontSizes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAllMedia } from 'state/slices/media';
import { getAllMedia } from 'state/slices/media/mediaThunks';
import { projectMediasRequested } from 'state/slices/mediasSlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';
import styled from 'styled-components';
import { formatNameFromPath } from '../helpers';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { ThumbnailGrid } from './ThumbnailGrid/ThumbnailGrid';
import { ToggleLegacyViewButton } from './ToggleLegacyViewButton/ToggleLegacyViewButton';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

const MainContainer = styled.div`
  margin: 1rem 2rem;
`;

const Title = styled.h2`
  @media screen and (max-width: ${breakpoints.xl}) {
    font-size: ${fontSizes.medium};
  }
`;

export const AlbumView = () => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId, categoryName } = useParams<{ projectId: string; categoryName: string }>();
  const { project } = useSelector((state: RootState) => state.project);
  const { projectMedias } = useSelector((state: RootState) => state.medias);
  const allMedias = useSelector((state: RootState) => selectAllMedia(state, projectId));
  const isAllPhotosCategorySelected =
    categoryName === ALL_PHOTOS_NAME || categoryName === ALL_PHOTOS_URL_ENCODED;

  const category = formatNameFromPath(categoryName ?? '');

  useEffect(() => {
    if (projectId) {
      dispatch(projectRequested(projectId));
      if (isAllPhotosCategorySelected) {
        dispatch(
          getAllMedia({
            projectId,
            withFileExtension: false,
          })
        );
      } else {
        dispatch(
          projectMediasRequested({
            projectId,
            mediaCategoryName: category,
            withFileExtension: false,
          })
        );
      }
    }
  }, [category, dispatch, isAllPhotosCategorySelected, projectId]);

  return (
    <MainContainer>
      <Wrapper>
        <div>
          <Title>{project?.name}</Title>
          <Breadcrumbs category={categoryName} projectId={projectId} />
        </div>
        <ToggleLegacyViewButton toLegacy={true} />
      </Wrapper>
      <ThumbnailGrid album={isAllPhotosCategorySelected ? allMedias : projectMedias} />
    </MainContainer>
  );
};
